import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en: {
    constitution: 'Constitution',
  },
  ru: {
    constitution: 'Конституция',
  },
  az: {
    constitution: 'Konstitusiya',
  },
};


const i18n = new VueI18n({
  locale: 'az',
  messages,
});

export default i18n;