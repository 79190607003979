import CustomStore from "devextreme/data/custom_store";
import constitutionService from "../../services/constitution.service";

const ConstitutionStore = {
  namespaced: true,

  state: {
    constitution: {},
    constitutions: [],
    translates: {},
    currentLanguage: 'az'
  },

  mutations: {
    SET_DATA(state, payload) {
      state.constitution = payload;
    },
    SET_LIST(state, payload) {
      state.constitutions = payload;
    },
    SET_TRANSLATES(state, payload) {
      state.translates = payload;
    },
    SET_CURRENT_LANGUAGE(state, language) {
      state.currentLanguage = language;
    }
  },

  actions: {
    getConstitutions({ commit }, payload = null) {
      return constitutionService.get(null, { datatable: true, ...payload })
        .then(r => {
          const data = r.data.response;

          console.log("Constitutions Data:", data.data);
          commit('SET_LIST', data.data); 
          return {
            data: data.data,
            totalCount: data.total
          };
        });
    },

    getConstitution({ commit }, payload) {
      return constitutionService.get(payload)
        .then(r => {
          commit('SET_DATA', r.data.response);
        });
    },

    getSelectConstitutions({ commit }, payload = {}) {
      return constitutionService.get(null, payload)
        .then(r => {
          commit('SET_LIST', r.data.response);
        });
    },

    getTranslates({ commit }, payload = null) {
      const data = new CustomStore({
        load: function (loadOptions) {
          return EventBoardService.post('translate', '', { datatable: true, ...loadOptions, ...payload })
            .then(r => {
              const data = r.data.response;
              return {
                data: data.data,
                totalCount: data.total
              };
            });
        }
      });
      commit('SET_TRANSLATES', data);
    },

    setConstitution({ commit }, payload) {
      if (payload.id) return constitutionService.put(payload.id, payload);
      return constitutionService.post(null, payload);
    },

    actionConstitution({ commit }, payload) {
      return constitutionService.post('action', payload);
    },

    deleteConstitution({ commit }, payload) {
      return constitutionService.delete(payload);
    },

    changeLanguage({ commit, dispatch }, language) {
      commit('SET_CURRENT_LANGUAGE', language);
      return dispatch('getConstitutions');
    },
  },

  getters: {
    getTranslatedDescription: (state) => (constitution) => {
      const lang = state.currentLanguage || 'az';
      return constitution.translates && constitution.translates[lang]
        ? constitution.translates[lang].name
        : '';
    }
  }
};

export default ConstitutionStore;
