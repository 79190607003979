<template>
  <page>
    <page-head>
      <app-button property="success" icon="icon-add" @click="createModal">
        {{ translate('button.Add') }}
      </app-button>
    </page-head>

    <page-body>
      <data-grid
          :data-source="constitutions"
          :columns="columns"
          :action-column-width="120"
          :action-column-text="translate('Əməliyyatlar')"
      >
        <div slot="actionSlot" slot-scope="list">
          <app-button
              v-if="can(permission + '.update')"
              @click="createModal(list.row.form)"
              :sm="true"
              :title="translate('button.Edit')"
              icon="icon-edit-2"
              property="primary"
          />


          <app-button
              v-if="can(permission + '.delete')"
              :sm="true"
              @click="remove(list.row.id)"
              :title="translate('button.Delete')"
              icon="icon-trash-2"
              property="danger"
          />
        </div>
      </data-grid>
    </page-body>

    <modal :id="modalId" size="md">
      <modal-head>
        <modal-title>{{ currentPage.title }}</modal-title>
      </modal-head>
      <modal-body v-if="modelShow">
        <form @submit.prevent="save">
          <grid>
            <form-group :label="'URL'" name="url">
              <form-text v-model="form.url"/>
            </form-group>

            <form-group
                :label="translate(translateKey + '.Label.Name') + (appLanguages.length > 1 ? ' ('+lang.name+')' : '')"
                :name="'translates.'+lang.code+'.name'"
                v-for="(lang, index) in appLanguages"
                :key="index"
            >
              <form-text v-model="form.translates[lang.code].name"/>
            </form-group>

            <form-group
                :label="translate(translateKey + '.Label.Content') + (appLanguages.length > 1 ? ' ('+lang.name+')' : '')"
                :name="'translates.'+lang.code+'.content'"
                v-for="(lang, index) in appLanguages"
                :key="'content'+index"
            >
              <form-html-editor type="simple" v-model="form.translates[lang.code].content"/>
            </form-group>


            <app-button class="justify-center" property="success" type="submit">
              {{ translate('button.Save') }}
            </app-button>
          </grid>
        </form>
      </modal-body>
    </modal>
  </page>
</template>

<script>
import { mapActions, mapState } from 'vuex';

const modalId = 'createModal';
const translateKey = 'crm.Constitution';

export default {
  name: "ConstitutionIndex",
  data() {
    return {
      translateKey,
      modalId,
      modelShow: false,
      columns: [
        {
          caption: 'Ad',
          dataField: 'name',
          show: true
        },
      ],
      form: {}
    }
  },
  computed: {
    ...mapState('ConstitutionStore', ['constitutions']),
    permission() {
      return this.currentPage.permission;
    }
  },
  methods: {
    ...mapActions('ConstitutionStore', ['getConstitutions', 'setConstitution', 'actionConstitution', 'deleteConstitution']),
    /*
     * Form Create
     * */
    formCreate(item = {}) {
      const form = {
        id: item.id || null,
        url: item.url || null,
        keywords: item.keywords || null,
        translates: {}
      }
      this.appLanguages.filter(i => {
        form.translates[i.code] = {
          name: item.translates && item.translates[i.code] ? item.translates[i.code].name : null,
          content: item.translates && item.translates[i.code] ? item.translates[i.code].content : null,
        }
      });
      this.form = form;
      this.modelShow = true;
    },
    /*
     * Create Modal
     * */
    createModal(item = {}) {
      this.modal(this.modalId)
      this.modelShow = false;
      this.resetError();
      this.formCreate(item);
    },
    /*
     * Remove
     * */
    remove(id) {
      this.alert().then(r => this.deleteConstitution(id).then(r => this.getConstitutions()))
    },
    /*
     * Action
     * */
    action(item, type) {
      let action = item.action[type] ? 0 : 1;
      this.actionConstitution({id: item.id, type, action}).then(r => this.getConstitutions())
    },
    /*
     * Save
     * */
    save() {
      this.setConstitution(this.form)
          .then(r => {
            if (r) {
              this.modal(this.modalId);
              this.getConstitutions();
            }
          })
    }
  },
  created() {
    this.getConstitutions();
  }
}
</script>